<template>
  <div v-if="option && option.img" class="answer">
    <img :src="option.img" class="answer__img" alt="" />
    <button v-if="!shouldShowChangeVoteButton" @click.prevent="vote" class="answer__button">Votar</button>
    <button v-else-if="isSameVote" @click.prevent="deleteVote" class="answer__button">Quitar voto</button>
    <button v-else @click.prevent="changeVote" class="answer__button">Cambiar voto</button>
  </div>
</template>
<script>
  export default {
    name: "visor",
    props: ["option", "question", "hasVotes"],
    data() {
      return {
        warning: false,
        optionSelected: null,
      };
    },
    computed: {
      shouldShowChangeVoteButton() {
        return this.hasVotes.some((vote) => vote.questionId === this.question._id);
      },
      isSameVote() {
        const idsQuestions = this.hasVotes.map((ele) => ele.questionId);
        const idsOptions = this.hasVotes.map((ele) => ele.optionId);
        return idsQuestions.includes(this.question._id) && idsOptions.includes(this.option._id);
      },
    },
    methods: {
      vote() {
        const questionId = this.question._id;
        const answerQuestionSelected = {
          questionId,
          optionId: this.option._id,
        };
        this.optionSelected = answerQuestionSelected;
        this.$emit("vote", answerQuestionSelected);
      },
      deleteVote(id) {
        const optionId = typeof id === "string" ? id : this.option._id;
        this.$emit("deleteCheck", { option: optionId, question: this.question._id });
      },
      changeVote() {
        if (this.optionSelected) {
          this.deleteVote(this.optionSelected.optionId);
          this.vote();
        }
      },
    },
  };
</script>
<style lang="scss">
  .answer {
    position: relative;
    order: -1;
    width: 100%;
    height: 310px;
    border-radius: 10px;
    &__img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
    &__button {
      position: absolute;
      bottom: 20px;
      right: 40px;
      border-radius: 6px;
      background-color: $primary;
      border: none;
      padding: 0.3rem 1.5rem;
      color: #fff;
      cursor: pointer;
    }
    @media (min-width: 376px) {
      height: 400px;
    }
    @media (min-width: 769px) {
      width: 100%;
      height: 100%;
      order: initial;
    }
  }
</style>
